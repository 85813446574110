<template>
  <div class="home">
    <button class="button field is-info is-light" @click="isCardModalActive = true">
      <b-icon icon="plus"></b-icon>
      <span>Novo cliente</span>
    </button>
    <b-table :data="allFilterCustomers" ref="table" paginated per-page="10" detailed detail-key="id" :striped="true"
      :debounce-search="1000" @details-open="(row) => $buefy.toast.open(`Visualizando os detalhes de ${row.name}`)
        " :show-detail-icon="true" aria-next-label="Next page" aria-previous-label="Previous page"
      aria-page-label="Page" aria-current-label="Current page">
      <b-table-column field="id" label="ID" width="40" numeric v-slot="props">{{
        props.row.id
      }}</b-table-column>

      <b-table-column field="name" label="Nome" :searchable="true" sortable v-slot="props">
        <template v-if="showDetailIcon">{{
          props.row.name.toUpperCase()
        }}</template>
        <template v-else>
          <a :style="{
            'font-weight': props.row.total_services > 0 ? 'bold' : 'normal',
            color:
              props.row.total_services > 0
                ? 'green' + ' !important'
                : 'dark' + ' !important',
          }" @click="props.toggleDetails(props.row)">{{ props.row.name.toUpperCase() }}</a>
        </template>
      </b-table-column>

      <b-table-column field="identification" label="CPF/CNPJ" :searchable="true" sortable v-slot="props">{{
        props.row.identification }}</b-table-column>

      <b-table-column field="registration" label="IE/RG" :searchable="true" sortable v-slot="props">{{
        props.row.registration
      }}</b-table-column>

      <b-table-column field="email" label="E-mail" sortable :searchable="true" v-slot="props">{{
        props.row.email
          ? props.row.email.toUpperCase()
          : 'Nenhum e-mail definido'
      }}</b-table-column>

      <template slot="detail" slot-scope="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <p>
                <strong>{{ props.row.name.toUpperCase() }}</strong>
                <br />
                <strong>CPF/CNPJ: </strong>
                <small>{{ props.row.identification }}</small>
                <br />
                <strong>IE/RG: </strong>
                <small>{{ props.row.registration }}</small>
                <br />
                <span v-if="props.row.email">
                  <strong>E-mail: </strong>
                  <small>{{ props.row.email.toUpperCase() }}</small>
                  <br />
                </span>
                <span v-if="props.row.phone_number">
                  <strong>Telefone: </strong>
                  <small>{{ props.row.phone_number }}</small>
                  <br />
                </span>
                <strong>Endereço: </strong>
                <small>{{ props.row.street }}</small>
                <br />
                <strong>Cidade: </strong>
                <small>{{ props.row.city }}</small>
                <br />
                <strong>Estado: </strong>
                <small>{{ props.row.state }}</small>
              </p>
            </div>
          </div>
        </article>
      </template>
      <b-table-column label="Opções" v-slot="props">
        <button class="button is-small is-light" @click.prevent="
          onEdit(props.row)
        isCardModalActive = true
          ">
          <b-icon icon="pen" size="is-small"></b-icon>
        </button>
        <button class="button is-small is-danger" @click.prevent="onDelete(props.row.id)">
          <b-icon icon="delete" size="is-small"></b-icon>
        </button>
      </b-table-column>
    </b-table>
    <b-modal v-model="isCardModalActive" :width="640" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <b-field label="Nome/Razão social">
              <b-input required v-model="model.name"></b-input>
            </b-field>
            <b-field label="CPF/CNPJ">
              <b-input required v-model="model.identification"></b-input>
            </b-field>
            <b-field label="IE/RG">
              <b-input required v-model="model.registration"></b-input>
            </b-field>
            <b-field label="Telefone">
              <b-input v-model="model.phone_number"></b-input>
            </b-field>
            <b-field label="Dia de faturamento">
              <b-input required type="number" v-model="model.revenue_day"></b-input>
            </b-field>
            <b-field label="Taxa para serviço">
              <b-input required type="number" v-model="model.fee_service"></b-input>
            </b-field>
            <b-field label="E-mail">
              <b-input type="email" v-model="model.email"></b-input>
            </b-field>
            <b-field label="Endereço">
              <b-autocomplete :data="listAddress" placeholder="Pesquisar endereço..." field="address"
                :loading="dataset.loading" @typing="getAddressByName" v-model="model.address"
                @select="option => model.address_iid = option.placeId">

                <template slot-scope="props">
                  {{ props.option.address }}
                </template>

              </b-autocomplete>
            </b-field>
            <b-field label="Taxas"></b-field>
            <b-field label="Adicional por localidade extra">
              <b-input required v-money="moneyFormat" v-model="model.extra_local_value"></b-input>
            </b-field>
            <b-field label="Hora parada">
              <b-input required v-money="moneyFormat" v-model="model.stop_time"></b-input>
            </b-field>
            <b-field label="Valor feriado (%)">
              <b-input required type="number" v-model="model.holiday_value"></b-input>
            </b-field>
            <b-field label="Aprovação automática"></b-field>
            <b-field label="Horário Início">
              <b-input placeholder="Informe um horário de início" v-model="model.start_time" v-cleave="mask.time">
              </b-input>
            </b-field>
            <b-field label="Horário Final">
              <b-input placeholder="Informe um horário de término" v-model="model.end_time" v-cleave="mask.time">
              </b-input>
            </b-field>
            <b-field label="Intervalos"></b-field>
            <b-field v-for="(item, i) in model.time_intervals" :key="i">
              <div class="columns">
                <div class="column">
                  <b-select placeholder="Selecione um intervalo" required v-model="item.time_interval_id">
                    <option v-for="(item, index) in allTimeIntervals" :key="index" :value="item.id">
                      {{ item.init_time }} - {{ item.end_time }}
                    </option>
                  </b-select>
                </div>
                <div class="column">
                  <b-input placeholder="Informe a porcentagem" v-model="item.value" type="number">
                  </b-input>
                </div>
              </div>
            </b-field>
            <button class="button field is-info is-light" @click="addInterval">
              <b-icon icon="plus"></b-icon>
              <span>Adicionar intervalo</span>
            </button>
            <b-button style="margin-top: 50px" @click="save" type="button field is-info" class="mt-10">Salvar</b-button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { EventBus } from '../event-bus.js'
import service from '../features/customers/store/service'
import Customer from '../models/customer'

import debounce from 'lodash/debounce'

import axios from 'axios';

import Cleave from 'cleave.js'

/**
 * We add a new instance of Cleave when the element
 * is bound and destroy it when it's unbound.
 */
const cleave = {
  name: 'cleave',
  bind(el, binding) {
    const input = el.querySelector('input')
    input._vCleave = new Cleave(input, binding.value)
  },
  unbind(el) {
    const input = el.querySelector('input')
    input._vCleave.destroy()
  },
}

export default {
  name: 'Customers',
  directives: { cleave },
  data() {
    return {
      model: Customer.model,
      moneyFormat: {
        prefix: 'R$ ',
        sstatefix: '',
        precision: 2,
        masked: false,
      },
      mask: {
        time: { time: true, timePattern: ['h', 'm'] },
      },
      dataset: {
        loading: false
      },
      listAddress: [],
      allFilterCustomers: [],
      showDetailIcon: false,
      isCardModalActive: false,
    }
  },
  created() {
    this.init()
  },
  computed: {
    ...mapState('customers', ['allCustomers']),
    ...mapState('timeIntervals', ['allTimeIntervals']),
  },
  methods: {
    ...mapActions('customers', ['getAllCustomers']),
    ...mapActions('timeIntervals', ['getAllTimeIntervals']),
    async init() {
      await this.getAllCustomers()
      await this.getAllTimeIntervals()

      this.allFilterCustomers = this.allCustomers.sort((a, b) => {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      })

      console.log(this.model)
    },
    addInterval() {
      this.model.time_intervals.push({ time_interval_id: null, value: 0.0 })
    },
    async save() {
      const customer = { ...this.model }

      console.log('stop time ', customer.stop_time)

      customer.stop_time = customer.stop_time ? customer.stop_time.toString().replace('R$ ', '') : 0

      customer.stop_time = parseFloat(customer.stop_time)

      customer.extra_local_value = customer.extra_local_value ? customer.extra_local_value.toString().replace('R$ ', '') : 0

      customer.extra_local_value = parseFloat(customer.extra_local_value)

      try {
        const save = customer.id
          ? await service.updateCustomer(customer)
          : await service.saveCustomer(customer)

        await this.$success('Cliente')
        await location.reload(true)
      } catch (error) {
        this.$error(
          error.response.data.message || error.response.data[0].message
        )
      }
    },
    onEdit(item) {
      this.model = { ...item }

      if (this.model.stop_time) {
        this.model.stop_time = parseFloat(this.model.stop_time).toFixed(2)
      }

      if (this.model.extra_local_value) {
        this.model.extra_local_value = parseFloat(this.model.extra_local_value).toFixed(2)
      }

      this.model.time_intervals = item.time_intervals.map((x) => {
        return {
          time_interval_id: x.id,
          value: x.pivot.value
        }
      })
      console.log(item)
    },
    async onDelete(id) {
      try {
        const result = await this.$ask('Você deseja remover o cliente?')

        if (result.isConfirmed) {
          await service.deleteCustomer(id)

          await this.$delete('Cliente')
          location.reload(true)
        }
      } catch (error) {
        this.$error(error.response.data.message)
      }
    },

    getAddressByName: debounce(async function (name) {
      if (!name.length) {
        this.listAddress = []
        return
      }
      // this.isFetching = true

      this.dataset.loading = true;

      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}api/v2/google-maps/find/${name}`, {
          headers: {
            'Access-Control-Allow-Origin': `${process.env.VUE_APP_API_URL}/api`,
            'access-control-allow-headers': 'Authorization',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
        })

        console.log('response ', response.data)

        this.listAddress = response.data.content;

      } catch (error) {

      } finally {
        this.dataset.loading = false;
      }
    }, 500)
  },
}
</script>

<style scoped></style>
